import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { memo } from "react";

function SelectControl(props) {
  const {
    variant,
    label,
    name,
    value,
    onChange,
    none = false,
    options,
    error = null,
  } = props;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: { maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP },
    },
  };

  return (
    <FormControl
      variant={variant || "outlined"}
      {...(error && { error: true })}
      margin="dense"
      fullWidth
    >
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        MenuProps={MenuProps}
      >
        {none && <MenuItem value="none">None</MenuItem>}
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label ?? option.value}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

export default memo(SelectControl);
