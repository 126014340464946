import { Alert, Divider } from "@mui/material";
import { memo, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import ButtonControl from "../components/controls/ButtonControl";
import InputControl from "../components/controls/InputControl";
import SelectControl from "../components/controls/SelectControl";
import { useForm } from "../components/useForm";
import useAppContext from "../hooks/useAppContext";
import { countriesArr } from "../scripts/Scripts";

function EditPersonalInfo(props) {
  const { quote, setQuote, setIsEdit } = props;
  const { apiPortal, auth } = useAppContext();
  const [isSaving, setIsSaving] = useState(false);
  const [fetchError, setFetchError] = useState(null);

  const validate = (input = values) => {
    let temp = { ...errors };

    // Customer Info validation
    if ("email" in input) {
      temp.email = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i.test(
        input.email
      )
        ? ""
        : "Email in not valid.";
      temp.email = input.email ? temp.email : "Email is required.";
    }
    if ("firstName" in input)
      temp.firstName = input.firstName ? "" : "This field is required.";
    if ("lastName" in input)
      temp.lastName = input.lastName ? "" : "This field is required.";
    if ("city" in input)
      temp.city = input.city ? "" : "This field is required.";
    if ("state" in input)
      temp.state = input.state ? "" : "This field is required.";
    if ("country" in input)
      temp.country = input.country ? "" : "This field is required.";
    if ("zipCode" in input)
      temp.zipCode = input.zipCode ? "" : "This field is required.";
    if ("phoneNumber" in input)
      temp.phoneNumber = input.phoneNumber ? "" : "This field is required.";

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors } = useForm(
    quote.customer,
    true,
    validate
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    values[name] = value;

    setValues({ ...values });
    validate({ [name]: value });
  };

  const handleSubmit = async (e) => {
    setIsSaving(true);
    e.preventDefault();
    setIsSaving(true);
    const abortCtrl = new AbortController();

    // PUT Request using fetch
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    };

    try {
      const response = await fetch(
        `${apiPortal}/Customers/${quote.customer.customerId}`,
        requestOptions,
        {
          signal: abortCtrl.signal,
        }
      );
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson && (await response.json());

      // check for error response
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        throw Error(error);
      }

      setQuote({ ...quote, customer: values });
      setIsEdit(false);
    } catch (error) {
      setFetchError(
        <Alert severity="error">{!error.message ? error : error.message}</Alert>
      );
    } finally {
      setIsSaving(false);
      abortCtrl.abort();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <InputControl
        label="First Name"
        name="firstName"
        value={values.firstName}
        onChange={handleInputChange}
        error={errors.firstName}
      />
      <InputControl
        label="Last Name"
        name="lastName"
        value={values.lastName}
        onChange={handleInputChange}
        error={errors.lastName}
      />
      <PhoneInput
        value={values.phoneNumber}
        enableSearch={true}
        onChange={(phone) =>
          setValues({
            ...values,
            phoneNumber: phone === "" ? phone : "+" + phone,
          })
        }
        containerStyle={{
          marginTop: "16px",
          marginBottom: "8px",
        }}
        inputStyle={{
          width: "100%",
        }}
      />
      <InputControl
        label="Address"
        name="address"
        value={values.address}
        onChange={handleInputChange}
      />
      <InputControl
        label="City"
        name="city"
        value={values.city}
        onChange={handleInputChange}
        error={errors.city}
      />
      <InputControl
        label="State"
        name="state"
        value={values.state}
        onChange={handleInputChange}
        error={errors.state}
      />
      <InputControl
        label="Country"
        name="country"
        value={values.country}
        onChange={handleInputChange}
        options={countriesArr}
        textIndex={0}
      />
      <InputControl
        label="Zip Code"
        name="zipCode"
        value={values.zipCode}
        onChange={handleInputChange}
        error={errors.zipCode}
      />
      <InputControl
        label="Company"
        name="company"
        value={values.company}
        onChange={handleInputChange}
        error={errors.company}
      />
      <InputControl
        label="Tax No."
        name="taxNumber"
        value={values.taxNumber}
        onChange={handleInputChange}
        error={errors.taxNumber}
      />
      <SelectControl
        label="Where Did You Find Us"
        name="whereFindUs"
        value={values.whereFindUs}
        onChange={handleInputChange}
        options={[
          { value: "Social Media", label: "Social Media" },
          { value: "TV", label: "TV" },
          { value: "Friend", label: "Friend" },
          { value: "Google", label: "Google" },
          { value: "Other", label: "Other" },
        ]}
      />
      <Divider sx={{ my: 1 }}>{fetchError && fetchError}</Divider>
      <ButtonControl type="submit" text="submit" isSaving={isSaving} />
      <ButtonControl
        text="Cancel"
        variant="outlined"
        onClick={() => setIsEdit(false)}
        sx={{ ml: 0.5 }}
      />
    </form>
  );
}

export default memo(EditPersonalInfo);
