import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Alert,
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Fab,
  FormControl,
  Grid,
  Icon,
  ImageList,
  ImageListItem,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import FilesUploader from "../../components/FilesUploader";
import PageHeader from "../../components/PageHeader";
import ServiceCard from "../../components/ServiceCard";
import ButtonControl from "../../components/controls/ButtonControl";
import InputControl from "../../components/controls/InputControl";
import RadioGroupControl from "../../components/controls/RadioGroupControl";
import SelectControl from "../../components/controls/SelectControl";
import useAppContext from "../../hooks/useAppContext";
import PersonalInfoForm from "./PersonalInfoForm";

const objRecord = {
  industry: "Architectural",
  email: "",
  firstName: "",
  lastName: "",
  address: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
  phoneNumber: "",
  company: "",
  taxNumber: "",
  whereFindUs: "",
};

export default function ArchitecturalForm() {
  const { apiRequest, response, setResponse } = useAppContext();

  const [values, setValues] = useState({ ...objRecord });
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [imgList, setImgList] = useState([]);
  const [isEmailConflict, setEmailConflict] = useState(false);
  const [isNotFound, setNotFound] = useState(false);
  const location = useLocation();

  const validate = (input = values) => {
    let temp = { ...errors };

    // Customer Info validation
    if ("email" in input) {
      temp.email = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i.test(
        input.email
      )
        ? ""
        : "Email in not valid.";
      temp.email = input.email ? temp.email : "Email is required.";
    }
    if ("firstName" in input)
      temp.firstName = input.firstName ? "" : "This field is required.";
    if ("lastName" in input)
      temp.lastName = input.lastName ? "" : "This field is required.";
    if ("city" in input)
      temp.city = input.city ? "" : "This field is required.";
    if ("state" in input)
      temp.state = input.state ? "" : "This field is required.";
    if ("country" in input)
      temp.country = input.country ? "" : "This field is required.";
    if ("zipCode" in input)
      temp.zipCode = input.zipCode ? "" : "This field is required.";
    if ("phoneNumber" in input)
      temp.phoneNumber = input.phoneNumber ? "" : "This field is required.";

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e, switchName = null) => {
    const { name, value } = e.target;
    values[name] = value;
    if (switchName) values[switchName] = true;

    setValues({ ...values });
    validate({ [name]: value });
  };

  const [step, setStep] = useState(0);
  const [items, setItems] = useState([]);
  const nextStep = () => {
    // ARRAY: create selected services array;
    const x = Object.keys(values).filter((k) => k.startsWith("_") && values[k]);
    setItems(x);
    setStep(step + 1);
  };

  // Go back to prev step
  const prevStep = () => {
    setStep(step - 1);
  };

  const showPreview = async (e) => {
    if (e.target.files) {
      // Set selected images files to upload in values
      const name = e.target.name;
      const files = e.target.files;
      setValues((prev) => ({ ...values, [name]: files }));

      // Crate Image list from selected image files to upload
      const fileArray = Array.from(files).map((file) =>
        URL.createObjectURL(file)
      );

      setImgList({ ...imgList, [name]: fileArray });
      // setImgList((prev) => prev.concat(...imgList[name], fileArray));
      // Array.from(imgList[name]).map((file) => URL.revokeObjectURL(file));
    }
  };

  const renderImageList = (source) => {
    return (
      <ImageList variant="masonry" cols={4} gap={8}>
        {source.map((el) => (
          <ImageListItem key={el}>
            <img src={el} alt={el} />
          </ImageListItem>
        ))}
      </ImageList>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      setIsSaving(true);

      const data = {};
      const quoteServices = {};
      const quoteItems = {};
      const quoteInfo = {};
      for (const [key, val] of Object.entries(values)) {
        if (key.startsWith("_") && val) {
          const k = key.slice(1);
          quoteServices[k] = val;
          continue;
        }

        if (/^[A-Z]/.test(key[0]) && val) {
          quoteItems[key] = typeof val === "boolean" ? "Yes" : val;
          continue;
        }

        if (/^[a-z]/.test(key[0])) {
          data[key] = val;
          continue;
        }
      }

      for (const [key] of Object.entries(data)) {
        if (key.endsWith("_") || key.startsWith("_")) {
          delete data[key];
        }
      }

      let record = { ...data };
      record.quoteServices = JSON.stringify(quoteServices);
      record.quoteItems = JSON.stringify(quoteItems);
      record.quoteInfo = JSON.stringify(quoteInfo);

      // Create formData
      const formData = new FormData();
      // Add quote values to formData
      for (const [key, val] of Object.entries(record)) {
        formData.append(key, val);
      }

      // Add buildingWrapFile values to formData
      if (values.buildingWrapFile) {
        for (const val of Object.values(values.buildingWrapFile)) {
          formData.append("buildingWrapFile", val);
        }
      }

      // Add canvasFramesFile values to formData
      if (values.canvasFramesFile) {
        for (const val of Object.values(values.canvasFramesFile)) {
          formData.append("canvasFramesFile", val);
        }
      }

      // Add furnitureFile values to formData
      if (values.furnitureFile) {
        for (const val of Object.values(values.furnitureFile)) {
          formData.append("furnitureFile", val);
        }
      }

      // Add wallWrapFile values to formData
      if (values.wallWrapFile) {
        for (const val of Object.values(values.wallWrapFile)) {
          formData.append("wallWrapFile", val);
        }
      }

      // Add windowFilmFile values to formData
      if (values.windowFilmFile) {
        for (const val of Object.values(values.windowFilmFile)) {
          formData.append("windowFilmFile", val);
        }
      }

      // Add infoFiles values to formData
      if (values.infoFile) {
        for (const val of Object.values(values.infoFile)) {
          formData.append("infoFile", val);
        }
      }

      // POST request using fetch
      const requestOptions = {
        method: "POST",
        body: formData,
      };

      try {
        const response = await fetch(`${apiRequest}/Quote`, requestOptions);
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const json = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          if (response.status === 409) {
            setEmailConflict(true);
            return;
          }

          if (response.status === 404 || response.status === 500) {
            setNotFound(true);
            return;
          }

          // get error message from body or default to response status
          const error = (json && json.message) || response.status;
          throw Error(error);
        }

        setEmailConflict(false);
        setNotFound(false);
        setResponse(record);
      } catch (error) {
        setFetchError(
          <Alert severity="error">
            {!error.message ? error : error.message}
          </Alert>
        );
      } finally {
        setIsSaving(false);
      }
    }
  };

  return (
    <>
      {response && (
        <Navigate to="/thank-you" state={{ from: location }} replace />
      )}
      <PageHeader
        title="Architectural Services"
        subTitle="Select one or more services to receive a tailored quote within 24h"
        icon="wi-architectural"
      />
      <Container sx={{ pb: 5 }}>
        <form onSubmit={handleSubmit}>
          <Slide
            direction="right"
            in={step === 0 ? true : false}
            style={{ display: step === 0 ? "flex" : "none" }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} md={9}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <ServiceCard
                      services={[
                        "BuildingWrapOption",
                        "buildingWrapFile",
                        "BuildingWrapQuantity",
                        "BuildingWrapHeight",
                        "BuildingWrapWidth",
                        "BuildingWrapUnits",
                        "BuildingWrapMoreInfo",
                      ]}
                      values={values}
                      setValues={setValues}
                      switchName="_BuildingWrap"
                      icon="wi-building-wrap"
                      title="Building Wrap"
                      paragraph="A building wrap is a simple, beautiful and cost-effective
                      solution to transform the appearance of your building
                      exterior. Wether it is just a colorchange or design, it is
                      an interesting solution for a building owner trying to rent
                      the space to future tenants. Or for a manager trying to
                      inspire and motivate employees. Or a business seeking to
                      build brand awareness while entertaining people passing by."
                    >
                      <RadioGroupControl
                        aria-label="building wrap"
                        name="BuildingWrapOption"
                        onChange={(e) => handleInputChange(e, "_BuildingWrap")}
                        options={[
                          {
                            label: "Design Request",
                            value: "Design Request",
                          },
                          {
                            label: "I Have a Design",
                            value: "I Have a Design",
                          },
                        ]}
                      />
                      <FormControl margin="dense" fullWidth>
                        <label htmlFor="buildingWrap-file">
                          <input
                            id="buildingWrap-file"
                            name="buildingWrapFile"
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={showPreview}
                            style={{ display: "none" }}
                          />
                          <ButtonControl
                            startIcon={<CloudUploadIcon />}
                            variant="outlined"
                            text="Upload Your Design / Example"
                            component="span"
                            fullWidth
                          />
                        </label>
                        {/* Render ImaList */}
                        {values.buildingWrapFile &&
                          renderImageList(imgList.buildingWrapFile)}
                      </FormControl>
                      <InputControl
                        type="number"
                        label="Quantity"
                        name="BuildingWrapQuantity"
                        onChange={(e) => handleInputChange(e, "_BuildingWrap")}
                      />
                      <Stack direction="row">
                        <InputControl
                          type="number"
                          label="Dimension Height"
                          name="BuildingWrapHeight"
                          onChange={(e) =>
                            handleInputChange(e, "_BuildingWrap")
                          }
                        />
                        <InputControl
                          type="number"
                          label="Dimension Width"
                          name="BuildingWrapWidth"
                          onChange={(e) => {
                            handleInputChange(e, "_BuildingWrap");
                            if (!values.BuildingWrapWidth)
                              setValues({ ...values, BuildingWrapUnits: "" });
                          }}
                        />
                        {values.BuildingWrapWidth && (
                          <SelectControl
                            label="Units"
                            name="BuildingWrapUnits"
                            value={
                              values.BuildingWrapUnits
                                ? values.BuildingWrapUnits
                                : ""
                            }
                            onChange={(e) =>
                              handleInputChange(e, "_BuildingWrap")
                            }
                            options={[
                              { label: "cm", value: "cm" },
                              { label: "Inch", value: "Inch" },
                            ]}
                          />
                        )}
                      </Stack>
                      <InputControl
                        label="Tell Me More"
                        name="BuildingWrapMoreInfo"
                        multiline
                        rows={3}
                        onChange={(e) => handleInputChange(e, "_BuildingWrap")}
                      />
                    </ServiceCard>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ServiceCard
                      services={[
                        "CanvasFramesOption",
                        "canvasFramesFile",
                        "CanvasFramesQuantity",
                        "CanvasFramesHeight",
                        "CanvasFramesWidth",
                        "CanvasFramesUnits",
                        "CanvasFramesMoreInfo",
                      ]}
                      values={values}
                      setValues={setValues}
                      switchName="_CanvasFrames"
                      icon="wi-canvas-frames"
                      title="Canvas and Frames"
                      paragraph="Your favorite photo printed on canvas magnifies its beauty.
                      Your image is printable in every size you wish. We perfectly
                      taut your canvas. No creasing or billowing, but just a
                      perfectly flat, firm canvas fabric."
                    >
                      <RadioGroupControl
                        aria-label="canvas frames"
                        name="CanvasFramesOption"
                        onChange={(e) => handleInputChange(e, "_CanvasFrames")}
                        options={[
                          {
                            label: "Design Request",
                            value: "Design Request",
                          },
                          {
                            label: "I Have a Design",
                            value: "I Have a Design",
                          },
                        ]}
                      />
                      <FormControl margin="dense" fullWidth>
                        <label htmlFor="canvasFrames-file">
                          <input
                            id="canvasFrames-file"
                            name="canvasFramesFile"
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={showPreview}
                            style={{ display: "none" }}
                          />
                          <ButtonControl
                            startIcon={<CloudUploadIcon />}
                            variant="outlined"
                            text="Upload Your Design / Example"
                            component="span"
                            fullWidth
                          />
                        </label>
                        {/* Render ImaList */}
                        {values.canvasFramesFile &&
                          renderImageList(imgList.canvasFramesFile)}
                      </FormControl>
                      <InputControl
                        type="number"
                        label="Quantity"
                        name="CanvasFramesQuantity"
                        onChange={(e) => handleInputChange(e, "_CanvasFrames")}
                      />
                      <Stack direction="row">
                        <InputControl
                          type="number"
                          label="Dimension Height"
                          name="CanvasFramesHeight"
                          onChange={(e) =>
                            handleInputChange(e, "_CanvasFrames")
                          }
                        />
                        <InputControl
                          type="number"
                          label="Dimension Width"
                          name="CanvasFramesWidth"
                          onChange={(e) => {
                            handleInputChange(e, "_CanvasFrames");
                            if (!values.CanvasFramesWidth)
                              setValues({ ...values, CanvasFramesUnits: "" });
                          }}
                        />
                        {values.CanvasFramesWidth && (
                          <SelectControl
                            label="Units"
                            name="CanvasFramesUnits"
                            value={
                              values.CanvasFramesUnits
                                ? values.CanvasFramesUnits
                                : ""
                            }
                            onChange={(e) =>
                              handleInputChange(e, "_CanvasFrames")
                            }
                            options={[
                              { label: "cm", value: "cm" },
                              { label: "Inch", value: "Inch" },
                            ]}
                          />
                        )}
                      </Stack>
                      <InputControl
                        label="Tell Me More"
                        name="CanvasFramesMoreInfo"
                        multiline
                        rows={3}
                        onChange={(e) => handleInputChange(e, "_CanvasFrames")}
                      />
                    </ServiceCard>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ServiceCard
                      services={[
                        "FurnitureOption",
                        "furnitureFile",
                        "FurnitureQuantity",
                        "FurnitureHeight",
                        "FurnitureWidth",
                        "FurnitureUnits",
                        "FurnitureMoreInfo",
                      ]}
                      values={values}
                      setValues={setValues}
                      switchName="_Furniture"
                      icon="wi-furniture"
                      title="Furniture"
                      paragraph="Vinyl wrap your furniture in a favorite color, a classic
                      finish or even a customized print. With vinyl wrapping, you
                      can do a quick make-over of almost any furniture piece at
                      very low cost. Upgrade your chairs, desks, cabinets and many
                      appliances."
                    >
                      <RadioGroupControl
                        aria-label="furniture"
                        name="FurnitureOption"
                        onChange={(e) => handleInputChange(e, "_Furniture")}
                        options={[
                          {
                            label: "Design Request",
                            value: "Design Request",
                          },
                          {
                            label: "I Have a Design",
                            value: "I Have a Design",
                          },
                        ]}
                      />
                      <FormControl margin="dense" fullWidth>
                        <label htmlFor="furniture-file">
                          <input
                            id="furniture-file"
                            name="furnitureFile"
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={showPreview}
                            style={{ display: "none" }}
                          />
                          <ButtonControl
                            startIcon={<CloudUploadIcon />}
                            variant="outlined"
                            text="Upload Your Design / Example"
                            component="span"
                            fullWidth
                          />
                        </label>
                        {/* Render ImaList */}
                        {values.furnitureFile &&
                          renderImageList(imgList.furnitureFile)}
                      </FormControl>
                      <InputControl
                        type="number"
                        label="Quantity"
                        name="FurnitureQuantity"
                        onChange={(e) => handleInputChange(e, "_Furniture")}
                      />
                      <Stack direction="row">
                        <InputControl
                          type="number"
                          label="Dimension Height"
                          name="FurnitureHeight"
                          onChange={(e) => handleInputChange(e, "_Furniture")}
                        />
                        <InputControl
                          type="number"
                          label="Dimension Width"
                          name="FurnitureWidth"
                          onChange={(e) => {
                            handleInputChange(e, "_Furniture");
                            if (!values.FurnitureWidth)
                              setValues({ ...values, FurnitureUnits: "" });
                          }}
                        />
                        {values.FurnitureWidth && (
                          <SelectControl
                            label="Units"
                            name="FurnitureUnits"
                            value={
                              values.FurnitureUnits ? values.FurnitureUnits : ""
                            }
                            onChange={(e) => handleInputChange(e, "_Furniture")}
                            options={[
                              { label: "cm", value: "cm" },
                              { label: "Inch", value: "Inch" },
                            ]}
                          />
                        )}
                      </Stack>
                      <InputControl
                        label="Tell Me More"
                        name="FurnitureMoreInfo"
                        multiline
                        rows={3}
                        onChange={(e) => handleInputChange(e, "_Furniture")}
                      />
                    </ServiceCard>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ServiceCard
                      services={[
                        "WallWrapOption",
                        "wallWrapFile",
                        "WallWrapQuantity",
                        "WallWrapHeight",
                        "WallWrapWidth",
                        "WallWrapUnits",
                        "WallWrapMoreInfo",
                      ]}
                      values={values}
                      setValues={setValues}
                      switchName="_WallWrap"
                      icon="wi-wall-wrap"
                      title="Wall Wrap"
                      paragraph="Wall wraps are digital printed graphics. They can feature
                      any message, style or information you desire. Exterior as
                      well as interior walls can be wrapped. They can be installed
                      on almost any surface."
                    >
                      <RadioGroupControl
                        aria-label="wall wrap"
                        name="WallWrapOption"
                        onChange={(e) => handleInputChange(e, "_WallWrap")}
                        options={[
                          {
                            label: "Design Request",
                            value: "Design Request",
                          },
                          {
                            label: "I Have a Design",
                            value: "I Have a Design",
                          },
                        ]}
                      />
                      <FormControl margin="dense" fullWidth>
                        <label htmlFor="wallWrap-file">
                          <input
                            id="wallWrap-file"
                            name="wallWrapFile"
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={showPreview}
                            style={{ display: "none" }}
                          />
                          <ButtonControl
                            startIcon={<CloudUploadIcon />}
                            variant="outlined"
                            text="Upload Your Design / Example"
                            component="span"
                            fullWidth
                          />
                        </label>
                        {/* Render ImaList */}
                        {values.wallWrapFile &&
                          renderImageList(imgList.wallWrapFile)}
                      </FormControl>
                      <InputControl
                        type="number"
                        label="Quantity"
                        name="WallWrapQuantity"
                        onChange={(e) => handleInputChange(e, "_WallWrap")}
                      />
                      <Stack direction="row">
                        <InputControl
                          type="number"
                          label="Dimension Height"
                          name="WallWrapHeight"
                          onChange={(e) => handleInputChange(e, "_WallWrap")}
                        />
                        <InputControl
                          type="number"
                          label="Dimension Width"
                          name="WallWrapWidth"
                          onChange={(e) => {
                            handleInputChange(e, "_WallWrap");
                            if (!values.WallWrapWidth)
                              setValues({ ...values, WallWrapUnits: "" });
                          }}
                        />
                        {values.WallWrapWidth && (
                          <SelectControl
                            label="Units"
                            name="WallWrapUnits"
                            value={
                              values.WallWrapUnits ? values.WallWrapUnits : ""
                            }
                            onChange={(e) => handleInputChange(e, "_WallWrap")}
                            options={[
                              { label: "cm", value: "cm" },
                              { label: "Inch", value: "Inch" },
                            ]}
                          />
                        )}
                      </Stack>
                      <InputControl
                        label="Tell Me More"
                        name="WallWrapMoreInfo"
                        multiline
                        rows={3}
                        onChange={(e) => handleInputChange(e, "_WallWrap")}
                      />
                    </ServiceCard>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ServiceCard
                      services={[
                        "WindowFilmOption",
                        "windowFilmFile",
                        "WindowFilmQuantity",
                        "WindowFilmHeight",
                        "WindowFilmWidth",
                        "WindowFilmUnits",
                        "WindowFilmMoreInfo",
                      ]}
                      values={values}
                      setValues={setValues}
                      switchName="_WindowFilm"
                      icon="wi-window-film"
                      title="Window Film"
                      paragraph="A sign shows a thought, a wish or a command. Advertise,
                      announce, or celebrate with beautiful custom signs. We
                      create signs for your promotions, special events, grand
                      openings, trade shows, festivals and much more."
                    >
                      <RadioGroupControl
                        aria-label="window film"
                        name="WindowFilmOption"
                        onChange={(e) => handleInputChange(e, "_WindowFilm")}
                        options={[
                          {
                            label: "Design Request",
                            value: "Design Request",
                          },
                          {
                            label: "I Have a Design",
                            value: "I Have a Design",
                          },
                        ]}
                      />
                      <FormControl margin="dense" fullWidth>
                        <label htmlFor="windowFilm-file">
                          <input
                            id="windowFilm-file"
                            name="windowFilmFile"
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={showPreview}
                            style={{ display: "none" }}
                          />
                          <ButtonControl
                            startIcon={<CloudUploadIcon />}
                            variant="outlined"
                            text="Upload Your Design / Example"
                            component="span"
                            fullWidth
                          />
                        </label>
                        {/* Render ImaList */}
                        {values.windowFilmFile &&
                          renderImageList(imgList.windowFilmFile)}
                      </FormControl>
                      <InputControl
                        type="number"
                        label="Quantity"
                        name="WindowFilmQuantity"
                        onChange={(e) => handleInputChange(e, "_WindowFilm")}
                      />
                      <Stack direction="row">
                        <InputControl
                          type="number"
                          label="Dimension Height"
                          name="WindowFilmHeight"
                          onChange={(e) => handleInputChange(e, "_WindowFilm")}
                        />
                        <InputControl
                          type="number"
                          label="Dimension Width"
                          name="WindowFilmWidth"
                          onChange={(e) => {
                            handleInputChange(e, "_WindowFilm");
                            if (!values.WindowFilmWidth)
                              setValues({ ...values, WindowFilmUnits: "" });
                          }}
                        />
                        {values.WindowFilmWidth && (
                          <SelectControl
                            label="Units"
                            name="WindowFilmUnits"
                            value={
                              values.WindowFilmUnits
                                ? values.WindowFilmUnits
                                : ""
                            }
                            onChange={(e) =>
                              handleInputChange(e, "_WindowFilm")
                            }
                            options={[
                              { label: "cm", value: "cm" },
                              { label: "Inch", value: "Inch" },
                            ]}
                          />
                        )}
                      </Stack>
                      <InputControl
                        label="Tell Me More"
                        name="WindowFilmMoreInfo"
                        multiline
                        rows={3}
                        onChange={(e) => handleInputChange(e, "_WindowFilm")}
                      />
                    </ServiceCard>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ServiceCard
                      services={["AddressDirections"]}
                      values={values}
                      setValues={setValues}
                      switchName="_OnSiteVisitRequest"
                      icon="wi-other"
                      title="On-Site Visit Request"
                    >
                      <InputControl
                        label="Address / Directions"
                        name="AddressDirections"
                        multiline
                        rows={3}
                        onChange={(e) =>
                          handleInputChange(e, "_OnSiteVisitRequest")
                        }
                      />
                    </ServiceCard>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ServiceCard
                      services={["OtherDetails"]}
                      values={values}
                      setValues={setValues}
                      switchName="_Other"
                      icon="wi-other"
                      title="Other"
                      paragraph="Select this service for all other inquiries."
                    >
                      <InputControl
                        label="Please Specify"
                        name="OtherDetails"
                        multiline
                        rows={3}
                        onChange={(e) => handleInputChange(e, "_Other")}
                      />
                    </ServiceCard>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3} align="center">
                <Fab
                  component={Link}
                  to="/"
                  aria-label="go back"
                  sx={{ mb: 3 }}
                >
                  <ArrowBackIcon />
                </Fab>
                <Typography color="text.secondary">
                  Select services form left and click CONFIRM once done
                </Typography>
                <Divider variant="middle" sx={{ my: 2 }}>
                  <Avatar>
                    <Icon className="wi-architectural" />
                  </Avatar>
                </Divider>
                {Object.keys(values).some(
                  (k) => /^[A-Z]/.test(k[0]) && values[k]
                ) ? (
                  <ButtonControl onClick={nextStep} text="CONFIRM" />
                ) : (
                  <ButtonControl text="CONFIRM" disabled />
                )}
              </Grid>
            </Grid>
          </Slide>

          <Slide
            direction="left"
            in={step === 1 ? true : false}
            style={{ display: step === 1 ? "flex" : "none" }}
          >
            <Grid container>
              <Grid item xs={12} md={9}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Card elevation={3} sx={{ height: "100%" }}>
                      <CardHeader
                        avatar={<InfoOutlinedIcon fontSize="large" />}
                        title="Other Info"
                      />
                      <Divider />
                      <CardContent>
                        <InputControl
                          label="Any Other Necessary Info"
                          name="necessaryInfo_"
                          value={values.necessaryInfo_}
                          onChange={handleInputChange}
                          error={errors.necessaryInfo_}
                          multiline
                          rows={3}
                        />
                        <FormControl margin="dense" fullWidth>
                          <InputLabel>Upload Pictures</InputLabel>
                          <FilesUploader
                            inputName="infoFile"
                            accept="image/*"
                            values={values}
                            setValues={setValues}
                          />
                        </FormControl>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PersonalInfoForm
                      values={values}
                      setValues={setValues}
                      handleInputChange={handleInputChange}
                      errors={errors}
                      isEmailConflict={isEmailConflict}
                      setEmailConflict={setEmailConflict}
                      isNotFound={isNotFound}
                      setNotFound={setNotFound}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3} align="center">
                <Fab onClick={prevStep} aria-label="go back" sx={{ mb: 3 }}>
                  <ArrowBackIcon />
                </Fab>
                <Typography color="text.secondary">
                  Selected services
                </Typography>
                <List>
                  {items &&
                    items.map((item) => (
                      <ListItem key={item}>
                        <ListItemIcon>
                          <CheckIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          primary={item
                            .slice(1)
                            .replace(/([A-Z])/g, " $1")
                            .trim()}
                        />
                      </ListItem>
                    ))}
                </List>
                <Divider variant="middle" sx={{ my: 2 }}>
                  {fetchError ? (
                    fetchError
                  ) : (
                    <Avatar>
                      <Icon className="wi-architectural" />
                    </Avatar>
                  )}
                </Divider>
                {!isSaving ? (
                  <ButtonControl
                    type="submit"
                    text="Submit"
                    isSaving={isSaving}
                  />
                ) : (
                  <ButtonControl
                    text="...Processing"
                    isSaving={isSaving}
                    disabled
                  />
                )}
              </Grid>
            </Grid>
          </Slide>
        </form>
      </Container>
    </>
  );
}
